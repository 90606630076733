<template>
  <div>
    <b-card 
      class="entities-card p-0 mt-1" 
      :class="isDraggable ? 'cursor-move grid-item' : 'cursor-pointer'" 
      @click="isDraggable ? '' : loadEntityInfo()"
    >
      <safe-img
        class="entity-avatar"
        :class="entity.imageURL ? 'w-100 h-100' : 'w-75 entities-placeholder'"
        :src="getImageResource(entity.imageURL)"
        :placeholder="placeholder"
        :alt="entityTitle"
        :retry="2000"
      />
      <h5 v-if="!entity.imageURL" class="mt-50 text-center w-75 truncated-text">
        {{ entityTitle }}
      </h5>
    </b-card>
    <entity-modal
      v-model="isModalVisible"
      :entity="entity"
      :type="type"
      @updated="changeEntity"
      @removed="$emit('removed', $event)"
    />
  </div>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import { getImageResource } from '@/@core/utils/image-utils';
import EntitiesPlaceholder from '@/assets/images/placeholders/light/entities.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import EntityModal from '@/@core/components/modal/EntityModal.vue';

export default {
  name: 'EntitiesCard',
  components: {
    SafeImg,
    EntityModal,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    type: {
      type: Object,
      required: false,
      default: null,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  // async created() {
  //   console.log('entity', this.entity);
  //   console.log('type', this.type);
  // },
  computed: {
    entityTitle() {
      const locale = this.$store.state.locale.currentLocale;

      return translateTranslationTable(locale, this.entity.name);
    },
    placeholder() {
      return EntitiesPlaceholder;
    },
  },
  methods: {
    changeEntity(data) {
      this.entity = data.data;
    },
    loadEntityInfo() {
      this.isModalVisible = true;
    },
    getImageResource,
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.entity-avatar {
  object-fit: contain;
  border-radius: 12px;
}
.entities-card {
  border: none;
  filter: none;
  width: 13rem;
  height: 13rem;
  margin-bottom: 0.5rem;
  white-space: normal;
}
.truncated-text {
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  min-width: 0;
}
.grid-item {
  box-shadow: 0px 0px 10px -2px $primary !important;
}

</style>
