import Service from '@/config/service-identifiers';
import $service from '@/config/container';
import OrderBy from '@/@core/constants/OrderBy';
import ActiveLinksLabels from '@/@core/constants/ActiveLinksLabels';

export const CommonRequestConfig = {
  locationsCount: 1000,
  linksCount: ActiveLinksLabels.length,
  emailsCount: 1000,
  phonesCount: 1000,
};

export default {
  async fetchPage({
    page,
    perPage,
    communityKey,
    listingType,
    typeKey,
    searchString,
    organizationType,
    employeeNumberRange,
    societyType,
    classifiers,
    customFields,
  }) {
    const response = await $service[Service.BackendClient].get(
      'organizations',
      {
        params: {
          ...CommonRequestConfig,
          orderByName: OrderBy.ASC,
          communityKey: communityKey,
          listingType,
          page,
          count: perPage,
          typeKey,
          organizationType,
          searchString,
          employeeNumberRange,
          societyType,
          classifiers,
          customFields
        },
      },
    );

    return response.data;
  },
  async fetchOrganizationByName({ communityKey, organizationName }) {
    const response = await $service[Service.BackendClient].get(
      'organizations',
      {
        params: {
          organizationName,
          communityKey: communityKey,
          linksCount: ActiveLinksLabels.length,
          // TODO: we should replace by 1 and use <LocationsMap /> in details
          // but we alos have to find a way to show all the locations when the user is editing it's locations.
          locationsCount: 100,
        },
      },
    );

    return response.data.data[0];
  },
  async updateOrganization({
    name,
    communityKey,
    organizationKey,
    headline, // TranslationTable;
    description, // TranslationTable;
    dateFounded, // timestamp
    employeeNumber, // number
    links, // Array<{ url: string; label: string; }>
    location, // LocationTemplate[];

    logo, // File
    deleteLogo,
    background, // File
    deleteBg,
  }) {
    const requestConfig = {
      communityKey,
      organizationKey,
      headline,
      description,
      dateFounded,
      employeeNumber,
      links,
      location,
      name,

      isBg: !!background,
      deleteBg,
      isLogo: !!logo,
      deleteLogo,
    };

    let response;
    if (logo || background) {
      const formData = new FormData();
      if (logo) {
        formData.append('logo', logo);
      }
      if (background) {
        formData.append('background', background);
      }
      formData.append('data', JSON.stringify(requestConfig));

      response = await $service[Service.BackendClient].put(
        'organizations',
        formData,
        { headers: { 'content-type': 'multipart/form-data' } },
      );
    } else {
      response = await $service[Service.BackendClient].put('organizations', requestConfig);
    }

    return response.data;
  },
  async createOrganization({
    name,
    communityKey,
    headline, // TranslationTable;
    description, // TranslationTable;
    dateFounded, // timestamp
    employeeNumber, // number
    links, // Array<{ url: string; label: string; }>
    location, // LocationTemplate[];

    logo, // File
    deleteLogo,
    background, // File
    deleteBg,
  }) {
    const requestConfig = {
      name,
      communityKey: communityKey,
      headline,
      description,
      dateFounded,
      employeeNumber,
      links,
      location,

      isBg: !!background,
      deleteBg,
      isLogo: !!logo,
      deleteLogo,
    };

    let response;
    if (logo || background) {
      const formData = new FormData();
      if (logo) {
        formData.append('logo', logo);
      }
      if (background) {
        formData.append('background', background);
      }
      formData.append('data', JSON.stringify(requestConfig));

      response = await $service[Service.BackendClient].post(
        'createOrganization',
        formData,
        { headers: { 'content-type': 'multipart/form-data' } },
      );
    } else {
      response = await $service[Service.BackendClient].post('createOrganization', requestConfig);
    }

    return response.data;
  },
};
