<template>
  <b-modal
    v-model="isVisible"
    hide-footer
    size="lg"
    centered
    :title="entityType"
    scrollable
    class="px-1"
    @hidden="editing = false"
    @shown="getAvatar"
  >
    <div class="trash-icon mt-50">
      <feather-icon
        v-if="canEdit && !editing"
        v-b-tooltip.hover.bottom
        icon="Trash2Icon"
        :title="$t('unshare.tooltip')"
        size="20"
        style="cursor: pointer"
        class="text-primary float-right"
        @click="isUnshareModalVisible = true"
      />
    </div>
    <b-dropdown
      v-if="canEdit && !editing"
      id="dropdown-dropleft"
      variant="link"
      right
      class="text-primary float-right mt-n75"
      no-caret
    >
      <template #button-content>
        <feather-icon icon="Edit2Icon" size="20" />
      </template>
      <b-dropdown-text class="bg-light">
        <p class="user-name font-weight-bolder mb-0">
          {{ $t("edit-dropdown.title") }}
        </p>
      </b-dropdown-text>
      <b-dropdown-item @click="editing = true">
        {{ $t("edit-dropdown.details") }}
      </b-dropdown-item>
      <b-dropdown-item @click="isClassifiersModalVisible = true">
        {{ $t("members.edit.classifiers.name") }}
      </b-dropdown-item>
    </b-dropdown>
    <!-- Unshare -->

    <feather-icon
      v-if="editing"
      icon="XCircleIcon"
      size="20"
      class="text-primary edit-icon float-right ml-1"
      role="button"
      @click="editing = !editing"
    />
    <b-button
      v-if="editing"
      class="float-right mt-n50"
      variant="primary"
      @click="handleEditItem"
    >
      {{ $t("form.actions.save-changes") }}
    </b-button>

    <b-spinner
      v-if="isLoading"
      small
      type="grow"
      class="d-block mx-auto text-primary mb-2"
    />
    <div
      v-else
      class="mb-2 mt-4"
      :class="
        editing
          ? 'justify-content-md-center d-lg-flex'
          : 'd-flex flex-column flex-md-row justify-content-center align-items-center '
      "
    >
      <!--Left side-->
      <safe-img
        v-if="!editing"
        class="modal-logo"
        :src="entity.imageURL"
        :placeholder="entityPlaceholder"
        :retry="1000"
      />
      <div>
        <div v-if="canEdit && editing" class="mt-5">
          <b-media no-body vertical-align="center">
            <b-media-body>
              <b-img
                ref="refPreviewEl"
                :src="avatarSrc || entityPlaceholder"
                class="d-block mx-auto"
                style="max-width: 150px"
              />
              <b-form-file
                v-model="imageURL"
                class="file-upload file-upload--upload w-100 mt-1"
                :placeholder="$t('form-create-item.image-button')"
              />
            </b-media-body>
          </b-media>
        </div>
      </div>
      <!--Right side-->
      <div class="mt-2 ml-md-2">
        <!-- Name -->
        <b-card-title v-if="!editing" class="m-0 text-center">
          {{ entityNameTranslated }}
        </b-card-title>
        <form-translation-table
          v-else
          id="name"
          v-model="entity.name"
          :name="$t('form-create-item.title')"
          :placeholder="$t('form-create-item.title')"
          type="text"
          required
        />
        <!-- <b-form-input
          v-model="entityNameTranslated"
          class="edit-title"
          :placeholder="entityNameTranslated "
        /> -->

        <!-- Headline -->
        <p v-if="entityHeadlineTranslated && !editing" class="pt-75 mb-25 text-center">
          {{ entityHeadlineTranslated }}
        </p>
        <form-translation-table
          v-else-if="editing"
          v-model="editForm.headline"
          class="edit-title mt-1"
          :placeholder="'os'"
        />

        <!-- Links -->
        <!-- Web link -->
        <p v-if="entity.link && !editing" class="mb-0 text-center">
          <a
            :href="entity.link"
            target="_blank"
            class="social-link align-text-top"
          >
            {{ $t("entities.visit-website") }}
          </a>
        </p>
        <b-form-input
          v-else-if="editing"
          v-model="entity.link"
          class="edit-title mt-1"
          :placeholder="$t('organizations.links-placeholder')"
        />

        <!-- Organization -->
        <b-button
          v-if="entity.organization && !editing"
          size="sm"
          variant="primary"
          class="mt-50"
          :to="organizationLocation"
        >
          {{ $t("entities.see-profile") }}
        </b-button>
        <members-select
          v-else-if="editing"
          v-model="entity.organization"
          clearable
          searchable
          class="mt-1"
          dir="ltr"
          input-id="profile-organizations"
          :placeholder="$t('organizations.search-placeholder')"
          :fetch-data="fetchData"
          label="name"
        />
        <v-select
          v-if="editing"
          id="type"
          v-model="selectedType"
          class="mt-1 style-chooser"
          height="10px"
          :options="types"
          :searchable="true"
          :placeholder="$t('form.select-placeholder')"
          label="name"
          :clearable="false"
        />
      </div>
      <!-- Unshare modal -->
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="entity"
        @remove-item="handleRemoveItem"
      />
    </div>

    <!-- eslint-disable-next-line vue/no-v-html-->
    <div
      v-if="
        !editing &&
          entity.description != null &&
          entity.description[locale] != null
      "
      class="mt-1 mb-4"
      v-html="entity.description[locale]"
    >
    </div>
    <quill-editor 
      v-else-if="editing" 
      v-model="editForm.description[locale]" 
    />
    
    <!-- Classifiers -->
    <template
      v-if="classifiers && classifiers.length > 0 && !editing && !isLoading"
      v-key="classifiers"
      class="my-50"
    >
      <hr>
      <div v-for="(value, name) in sortedClassifiers" :key="value.key">
        <div class="w-100">
          {{ name }}:
        </div>
        <span v-for="(v, index) in value" :key="index">
          <b-badge class="mb-50 mr-50" :style="classifierStyle(v)">
            {{
              translateTranslationTable(
                $store.state.locale.currentLocale,
                v.name
              )
            }}
          </b-badge>
        </span>
      </div>
    </template>
    <classifiers-modal
      v-model="isClassifiersModalVisible"
      :item-key="entity.key"
      morph-type="entities"
      model-type="entity"
      :classifiers="classifiers"
      @profileUpdated="fetchClassifiers"
    />
  </b-modal>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import EntitiesPlaceholder from '@/assets/images/placeholders/light/entities.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import { quillEditor } from 'vue-quill-editor';
import AjaxSelect from '@core/components/ajax-select/AjaxSelect.vue';
// import OrganizationsAPI from '@/views/apps/organization/store/OrganizationsAPI';
import vSelect from 'vue-select';
import FormTranslationTable from '@core/components/form-translation-table/FormTranslationTable.vue';
// import { getImageResource } from '@/@core/utils/image-utils';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import { checkPermissions } from '@/@core/utils/roles-utils';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';

export default {
  name: 'EntityModal',
  components: {
    SafeImg,
    quillEditor,
    AjaxSelect,
    vSelect,
    FormTranslationTable,
    UnshareModal,
    ClassifiersModal,
    MembersSelect,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
    entity: {
      type: Object,
      required: true,
    },
    type: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      editing: false,
      itemImage: null,
      imageURL: null,
      avatarSrc: null,
      editForm: {
        headline: {},
        description: {},
        type: '',
      },
      typesSelect: [],
      isUnshareModalVisible: false,
      isClassifiersModalVisible: false,
      isLoading: false,
      selectedType: null,

    };
  },
  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set(isVisible) {
        this.$emit('update:visible', isVisible);
      },
    },
    canEdit() {
      return (
        this.collective.isStaff
        || checkPermissions(
          'update',
          'entity',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    canDelete() {
      return (
        this.collective.isStaff
        || checkPermissions(
          'delete',
          'entity',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    communitySlug() {
      return this.$route.params.communityId;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    entityNameTranslated() {
      return translateTranslationTable(this.locale, this.entity.name);
    },
    entityDescriptionTranslated() {
      return translateTranslationTable(this.locale, this.entity.description);
    },
    entityHeadlineTranslated() {
      return translateTranslationTable(this.locale, this.entity.headline);
    },
    typeKey() {
      return translateTranslationTable(this.locale, this.entity.type.name);
    },
    entityPlaceholder() {
      return EntitiesPlaceholder;
    },
    entityType() {
      return translateTranslationTable(this.locale, this.entity.type?.name);
    },
    organizationLocation() {
      return {
        name: 'organization',
        params: {
          slug: this.entity.organization.slug,
          communityId: this.$route.params.communityId,
        },
      };
    },
    classifiersData() {
      if (this.$store.getters.classifiers.entity) {
        return this.$store.getters.classifiers.entity.unpaginated.reduce(
          (acc, current) => {
            const x = acc.findIndex((item) => item.key === current.key);
            if (x === -1) {
              return acc.concat([current]);
            }
            acc[x] = current;
            return acc;
          },
          [],
        );
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
    types() {
      if (this.$store.getters.types.AllEntitiesTypes) {
        const typesSelect = [];
        const uniqueChars = this.$store.getters.types.AllEntitiesTypes.unpaginated.filter((item, index, allTypes) => index === allTypes.findIndex((type) => (
          type.key === item.key
        )));
        uniqueChars.map((item) => typesSelect.push({
          name: this.translate(item.name),
          key: item.key,
        }));
        return typesSelect;
      } return [];
    },
  },
  watch: {
    item(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.comments = [];
        this.fetchComments();
      }
    },
    imageURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.avatarSrc = value;
            })
            .catch(() => {
              this.avatarSrc = null;
            });
        } else {
          this.avatarSrc = null;
        }
      }
    },
  },
  async created() {
    this.editForm.description = this.entity.description != null ? this.entity.description : {};
    this.editForm.headline = this.entity.headline != null ? this.entity.headline : {};
    this.avatarSrc = this.entity.imageURL;
    this.openModal();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async getInitialData() {
      try {
        await this.$store.dispatch('getItemsNode', {
          itemType: 'entities',
          forceAPICall: true,
          page: 1,
          perPage: 50,
          requestConfig: {
            orderByDate: -1,
          },
        });
        // this.$emit('items-loaded', response);
      } catch (error) {}
    },
    openModal() {
      if (this.types.length > 0 && this.type) {
        const index = this.types.findIndex((item) => item.key === this.type.key);
        if (index >= 0) {
          this.selectedType = this.types[index];
        } else this.selectedType = this.types[0];
      }
      return {};
    },
    async handleEditItem() {
      try {
        const requestConfig = { 
          name: {}, 
          description: {}, 
          headline: {} 
        };
        requestConfig.name = this.entity?.name;
        requestConfig.typeKey = this.selectedType?.key;
        requestConfig.link = this.entity?.link;
        requestConfig.description[this.locale] = this.editForm?.description[this.locale];
        requestConfig.headline = this.editForm?.headline;
        requestConfig.organizationKey = this.entity?.organization?.key;
        requestConfig.entityKey = this.entity?.key;

        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'entities',
            storedKey: this.selectedType?.key,
            noSet: true,
            requestConfig,
          },
          file: this.imageURL,
          forceUpdate: true,
        });
        
        this.editedItem = result;
        this.entity = result;
        this.$emit('updated', result);
        // this.updateBreadcrumbs();
        this.editing = false;
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.success-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch (error) {
        console.log(error )
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('entities.messages.error-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },

    fetchData(page, searchString = '', force = true) {
      const requestConfigOrganizations = {
        isOrganization: true,
        communityParentSlug: this.$store.getters.currentCollective.slug,
        searchString: searchString || '',
      };
      return this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'shareOrganizations',
        storedKey: 'entityOrganizations',
        searchString: searchString || '',
        page,
        perPage: 16,
        forceAPICall: force,
        requestConfig: requestConfigOrganizations,
      });
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.itemImage = files[0];
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleRemoveItem(executeDelete) {
      if (executeDelete === true) {
        try {
          await this.$store.dispatch('postItem', {
            type: 'unshareEntity',
            customName: 'entities',
            forceUpdate: true,
            requestConfig: {
              key: this.entity.key,
            },
          });
          this.isVisible = false;
          await this.getInitialData();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('entities.messages.success-remove'),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
          this.$emit('removed', this.entity.key);
          // this.$router.push({ name: 'entities', params: { communityId: this.$route.params.communityId } });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.entity'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
    async getAvatar() {
      this.avatarSrc = this.entity.imageURL;
      await this.fetchClassifiers();
    },
    async fetchClassifiers() {
      this.isLoading = true;
      await this.$store.dispatch('getItems', {
        storedKey: 'entity',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          entityKey: this.entity.key,
        },
      });
      this.isLoading = false;
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    translateTranslationTable,
  },
};
</script>
<style>
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu{
  max-height: 150px !important;
}

</style>
<style lang="scss" scoped>
  @import "@core/scss/base/pages/page-profile.scss";
  @import "~@core/scss/base/bootstrap-include";

.modal-logo {
  max-width:16rem;
  min-width: 10rem;
  border-radius:12px

}
.modal-avatar {
  width: auto;
  height: 14rem;
}
@include media-breakpoint-up(md) {
  .modal-avatar {
    width: 14rem;
  }
}
.avatar {
  width: 70px !important;
  height: 70px !important;
  object-fit: contain;
  border-radius: 50%;
  background: white;
}
.logo {
  width: 100px !important;
  height: 100px !important;
  object-fit: contain;
  border-radius: 50%;
  background: white;
}
.file-upload {
  &--upload::v-deep {
    width: auto;
    .custom-file-label {
      cursor: pointer;
      text-align: center;
      height: auto !important;
      @include button-outline-variant($primary);
      &::after {
        display: none;
      }
    }
  }
}

</style>
